import React from "react"
import "./simplePage.scss"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

function SimplePage({ post }) {
  return (
    <section className="blogPost">
      <div className="post-content-container mb-2">
        <GatsbyImage
          image={getImage(post?.featuredImage?.node.localFile)}
          alt="Blog image"
        />
        <div className="post-meta">
          <div className="post-date pb-2">{post?.date}</div> {" | "}
          <div className="post-author pb-2">By &nbsp;Hixon Mortgage</div>
        </div>
        <h1 className="post-title">{post?.title}</h1>
        <div
          className="post-up-container main_content"
          dangerouslySetInnerHTML={{ __html: post?.content }}
        ></div>
      </div>
    </section>
  )
}

export default SimplePage

import React from "react"
import BlogPost from "../components/SimplePage/simplePage"
import Layout from "../components/Layouts/layout"
import PostNavigation from "../components/PostNavigation/PostNavigation"
import "./news/index.scss"
import { graphql } from "gatsby"
import Seo from 'gatsby-plugin-wpgraphql-seo'

function simplePage({ data, pageContext }) {
  let post = data?.wpPage
  const seo = data?.wpPage?.seo

  return (
    <Layout>
      <Seo post={post} />
      <BlogPost post={post} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query($id: String!) {
    wpPage(id: { eq: $id }) {
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
      title
      slug
      content
      date(formatString: "MM. D.YY")
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          sourceUrl
        }
      }
    }
  }
`
export default simplePage
